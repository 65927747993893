.wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    gap: 8px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #013957;
    border-radius: 32px;
    min-width: 100px;
}

.label {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 0.04em;
    color: #FFFFFF;
    margin: 0;
    padding: 0;
    @media (--viewportSm){
        line-height: normal;
    } 
}

.icon {
    width: 14px;
    height: 14px;
    cursor: pointer;
}