.dropzoneWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 83px 82px;
    gap: 8px;
    min-height: 228px;
    border: 1px dashed rgba(183, 188, 191, 0.8);
    text-align: center;
    cursor: pointer;
    width: 100%;
}

.uploadIcon {
    width: 24px;
    height: 24px;
}

.info {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: var(--marketplaceColor)
}

.infoHighlight {
    composes: info;
    color: var(--marketplaceSecondaryColor)
}

.uploadInfo {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: var(--marketplaceColor)
}