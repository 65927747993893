.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  gap: 8px;
}

.title {
  color: #000;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
  padding: 0;
}

.info {
  color: var(--marketplaceColor);
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  padding: 0;
}

.downloadIcon {
  cursor: pointer;
  width: 24px;
  height: 24px;
}

.deleteIcon {
  cursor: pointer;
  color: var(--matterColor) !important;

  &:hover {
    color: red !important;
  }
}

.fileNameWrapper {
  display: flex;
  max-height: 50px;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  justify-content: center;
  justify-content: space-between;
  background-color: #f5f5f5;
  color: #777;
}

.fileName {
  flex-grow: 1;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  max-width: 150px;
  line-height: 22px;
  color: var(--marketplaceColor);
  text-align: start;
  margin: 0;
}

.fileExtensionWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-items: center;
  background-color: #d85141;
  margin-left: 15px;
  margin-right: 10px;
  border-radius: 5px;
  margin-top: 4px;
  margin-bottom: 6px;
  height: 35px;
  width: 35px;
}

.fileContent {
  min-height: 110px;
}

.fileExtension {
  color: white;
  font-family: Open Sans;
  font-weight: 400;
  font-size: 14px;
  margin: 5px;
  padding: 0px;
}

.cornerImage {
  height: 20px;
  align-self: flex-end;
  margin-bottom: -1px;
  margin-right: -1px;
}

.horizontalWrapper {
  display: flex;
  flex-direction: column;
  max-width: 240px;
  min-height: 150px;
  justify-content: flex-start !important;
  border: 1px solid #c3c7ca;
  width: 100%;
  align-items: flex-end;
  margin-top: 5px;
  margin-bottom: 5px;
}

.fileOperations {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 10px;
}

.uploadInfo {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: var(--marketplaceColor);
  text-align: start;
  width: 100%;
}